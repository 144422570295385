<template>
  <div>
    <h3 class="text-center">Project</h3>
    <b-button variant="outline-dark" to="add_project"
      ><b-icon icon="plus"></b-icon>Add</b-button
    >
    
    <!-- <b-card>
      <b-card-header> Filter </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <label for="datepicker-full-width">From</label>
            <b-form-datepicker
              id="datepicker-full-width"
              v-model="fromDate"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label for="datepicker-full">To</label>
            <b-form-datepicker
              id="datepicker-full"
              v-model="toDate"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <div class="text-center">
            <b-button @click="filterByDate" variant="primery">filter</b-button>
          </div>
        </b-row>
      </b-card-body>
    </b-card> -->
    <b-row>
            <p class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          v-if="!items"
          label="Large Spinner"
        ></b-spinner>
      </p>

      <p
        class="text-center"
        style="color: red"
        v-if="items && items.length == 0"
      >
        there is no data
      </p>
      <b-col md="3" v-for="(item, index) in items" :key="index">
        <b-card
          :title="item.header"
          :img-src="item.logo_image"
          img-alt="Image"
          img-width="100px"
          img-height="100px"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2 news-card"
        >
          <b-card-text class="colapsed-five-line-text">
            {{ item.details }}
          </b-card-text>

          <div class="text-center">
              <b-button
              variant="danger"
              style="float: left"
              @click="globalDelete('Projects', item.id)"
            >
              <b-spinner
                style="width: 5rem; height: 5rem"
                v-if="loading"
              ></b-spinner>
              Delete</b-button
            >
            <b-button variant="outline-dark" style="float: right;"
            class="float-right"
     @click="routeTo('edit', item)"
      ><b-icon icon="pencil"></b-icon>Edit</b-button
    >
           
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-pagination
      @input="pageChange(currentPage)"
      v-model="currentPage"
      v-if="!filterdData"
      align="center"
      :total-rows="rows"
      :per-page="perPage"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </div>
</template>
<script>
const {imageToBanner,getFields,postData,getFieldsPage,getPerPage} = require("../../assets/js/service")
export default {
  data() {
    return {
      fromDate: null,
      toDate: null,
      currentPage: 1,
      rows: 1,
      perPage: 1,
      filterdData:false,
      isBusy: false,
      items: null
    };
  },
  watch:{
        "$store.state.viewReload": {
      handler() {
        if (this.$store.state.viewReload) {
          this.onCreateLoading();
          this.$store.commit("set", ["viewReload", false]);
        }
      },
    },
  },
  mounted() {
   this.onCreateLoading();

    
  },
  methods: {
    onCreateLoading(){
 let token = localStorage.getItem('token');
    getFields('Projects/count',token).then(resp=>{
      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage('Projects',1,token).then(resp=>{
 this.items = resp.data;
      })
    })
    },
    pageChange(currentPage){
      let token = localStorage.getItem('token');
      getFieldsPage('Projects',currentPage,token).then(resp=>{
 this.items = resp.data;
      })
    },
    filterByDate(){
      this.filterdData = true;
      let token = localStorage.getItem('token');
      postData('news/filterByDate',{toDate:this.toDate,fromDate:this.fromDate},token).then(resp=>{
        this.items = resp.data.Data;
      })
    },
    routeTo(page, data) {
      this.$store.commit("set", ["singlePageData", data]);
      this.$router.push(page);
    },
     makeBanorimage(img) {
      return imageToBanner("lowQuality", img);
    },
  },
};
</script>
<style>
</style>